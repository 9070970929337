<template>
    <div id="login-section" class="align-items-center d-flex">
        <div class="container">
            <div class="row justify-content-center">
                <div
                    class="bg-opacity-25 bg-primary border border-primary col-10 col-sm-5 my-5 p-sm-5 px-2 py-4 rounded">
                    <form @submit.prevent="register()" class="needs-validation" novalidate>
                        <h3 class="text-center mb-4 text-primary">Register</h3>
                        <div class="mb-3">
                            <input v-model="form.name" type="text" class="form-control text-center"
                                   :class="{'is-invalid' : error}"
                                   id="name" required placeholder="NAME">
                        </div>
                        <div class="mb-3">
                            <input v-model="form.email" type="email" class="form-control text-center"
                                   :class="{'is-invalid' : error}"
                                   id="email" required placeholder="EMAIL">
                        </div>
                        <div class="mb-3">
                            <input v-model="form.password" type="password" class="form-control text-center"
                                   :class="{'is-invalid' : error}" id="password" required placeholder="PASSWORD">
                        </div>
                        <div class="mb-3">
                            <input v-model="form.c_password" type="password" class="form-control text-center"
                                   :class="{'is-invalid' : error}" id="c_password" required
                                   placeholder="CONFIRM PASSWORD">
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-primary w-100 text-light">Register</button>
                            Already have account?<a href="/" class="mt-2"> Login
                            </a>
                        </div>
                        <div v-if="error" class="invalid-feedback d-block text-center">
                            {{ error_msg }}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import JwtService          from "@/services/jwt.service";
import NotificationService from "../../services/notification.service";

export default {
    name   : "Register",
    data   : () => ({
        form     : {
            name      : "",
            email     : "",
            password  : "",
            c_password: ""
        },
        error    : false,
        error_msg: '',
    }),
    methods: {
        register() {
            this.error = false;
            ApiService.post('/register', this.form).then((data) => {
                this.$router.push({name: "Home"});
                NotificationService.success(data.data.message);
            }).catch(error => {
                this.error     = true;
                this.error_msg = error.response.data.message;
                NotificationService.error(this.error_msg);
            });
        }
    }
};
</script>
